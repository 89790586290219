/**
 * _app.tsx is
 */
import React from 'react';

import type { AppProps } from 'next/app';
import '../styles/normalize.css';
import '../styles/variables.css';
import '../styles/colors.css';
import '../styles/layouts.css';
import '../styles/index.css';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <Component {...pageProps} />
    </>
  );
};

export default MyApp;
